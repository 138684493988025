import axios from "axios";
import { useEffect, useReducer } from "react";
// import logger from "use-reducer-logger";
import { getError } from "../../utils/utils";
import Product from "../Product";


export default function Flavors() {

    const reducer = (state, action) => {
      switch (action.type) {
        case "FETCH_REQUEST":
          return { ...state, loading: true };
        case "FETCH_SUCCESS":
          return { ...state, products: action.payload, loading: false };
        case "FETCH_FAIL":
          return { ...state, loading: false, error: action.payload };

        default:
          return state;
      }
    };
    const [{ products }, dispatch] = useReducer(
      reducer,
      {
        products: [],
        loading: true,
        error: "",
      }
    );
    useEffect(() => {
      const fetchData = async () => {
        dispatch({ type: "FETCH_REQUEST" });
        try {
          const result = await axios.get("https://vapey-loc.onrender.com/api/products");
          dispatch({ type: "FETCH_SUCCESS", payload: result.data.products });
        } catch (error) {
          dispatch({ type: "FETCH_FAIL", payload: getError(error) });
        }
        // setProducts(result.data)
      };
      fetchData();
    }, []);

    return (
      <main className="py-5" id="prods">
        <div className="container text-center">
          <h2 className="text-light mb-5">
            {/* <span className="text-info">{products && products.length}</span>{" "} */}
            wähle jetzt deinen Lieblingsgeschmack
          </h2>
          <div className="-container row row-cols-lg-3 px-5 g-5 justify-content-center row-cols-md-2 row-cols-1">
            {products &&
              products.map((product) => (
                <Product product={product} key={product.slug}></Product>
              ))}
          </div>
        </div>
      </main>
    );
}