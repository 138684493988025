import React, { useEffect, useState } from "react";
import "./ProductsDash.css";
import AdminNav from "./AdminNav";
import { Table } from "react-bootstrap";
import ProductsDashRow from "./ProductsDashRow";

export default function ProductsDash() {
  const [products, setProducts] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  let filteredProducts = [];
  useEffect(() => {
    async function fetchProducts() {
      const response = await fetch("https://vapey-loc.onrender.com/api/products");
      const data = await response.json();
      setProducts(data.products);
    }
    fetchProducts();
  }, []);
  const handleFilterChange = (event) => {
    const filter = event.target.value;
    setFilterValue(filter);
  };
  if (filterValue === "outStock") {
    filteredProducts = products.filter((product) => product.countInStock === 0);
  }

  if (filterValue === "inStock") {
    filteredProducts = products.filter((product) => product.countInStock !== 0);
  }

  if (filterValue === "") {
    filteredProducts = products;
  }

  return (
    <>
      <AdminNav />
      <div className="products__page">
        <div className="products_page_content">
          <div className="products_table">
            <Table responsive className="products_table text-center">
              <thead>
                <tr>
                  <th colSpan={7}>
                    <div className="d-flex justify-content-end">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={handleFilterChange}
                      >
                        <option selected value="">
                          {filterValue === "" ? "Filter" : "Reset"}
                        </option>
                        <optgroup label="Stock state">
                          <option value="inStock">In stock</option>
                          <option value="outStock">Out of stock</option>
                        </optgroup>
                      </select>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>Product Name</th>
                  <th>Product Brand</th>
                  <th>Product Category</th>
                  <th>Product Price</th>
                  <th>Product Stock</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="text-center display-6">
                      No products found .
                    </td>
                  </tr>
                ) : (
                  filteredProducts.map((product, index) => (
                    <ProductsDashRow
                      rowKey={index}
                      index={index}
                      product={product}
                      products={products}
                      setProducts={setProducts}
                    />
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}
