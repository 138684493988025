import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
// import logger from "use-reducer-logger";
import { Store } from "../../Store";
import { getError } from "../../utils/utils";
import Loading from "../loading/Loading";

export default function Recommendation(props) {
  const { state } = useContext(Store);
  const { cart } = state;
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_REQUEST":
        return { ...state, loading: true };
      case "FETCH_SUCCESS":
        return { ...state, products: action.payload, loading: false };
      case "FETCH_FAIL":
        return { ...state, loading: false, error: action.payload };

      default:
        return state;
    }
  };
  const [{ products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(
          "https://vapey-loc.onrender.com/api/products"
        );
        dispatch({
          type: "FETCH_SUCCESS",
          payload: result.data.products,
        });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
      // setProducts(result.data)
    };
    fetchData();
  }, []);
    
    //k


  const excludedId = props.excludedId;

  const newData = products
    .filter((item) => item.slug !== excludedId)
    .sort(() => Math.random() - 0.5)
    .slice(0, 3);

  console.log(newData);

  return (
    <div className="also-like mb-5">
      <h2 className="mb-3">Du könntest auch mögen:</h2>
      {!products ||
      products.length < 1 ||
      products === undefined ||
      products === null ? (
        <Loading />
      ) : (
        <div className="like-products">
          {newData.map((prod) => 
            <div className="like-product">
              <Link to={`/product/${prod && prod.slug}`}>
                <div className="info"></div>
              </Link>
              <img src={prod && prod.image} alt="" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
