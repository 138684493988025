import React from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import './OrderDetails.css'

export default function OrderDetailsModal(props) {
  const { order, show, handleClose } = props;
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Order Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="my-2">
            <strong>Client :</strong> {order.shippingAddresse.fullName}
          </p>
          <p>
            <strong>Shipping address:</strong>
            <ul className="my-2">
              <li className="mb-2">
                Address: {order.shippingAddresse.address}
              </li>
              <li className="mb-2">City: {order.shippingAddresse.city}</li>
              <li className="mb-2">
                Postal Code: {order.shippingAddresse.postalCode}
              </li>
              <li>Country: {order.shippingAddresse.country}</li>
            </ul>
          </p>
          <p className="my-2">
            <strong>Order items :</strong>
            <div className="d-flex mt-3">
              {order.orderItems.map((item, index) => (
                <div className="d-flex flex-column align-items-center text-center w-50 flex-wrap">
                  <img src={item.image} alt="order item" width={100} />
                  <p>
                    Item name: <br /> {item.name}
                  </p>
                  <p>
                    Unit price: <br /> € {item.price}
                  </p>
                  <p>
                    Quantity: <br /> {item.quantity}
                  </p>
                </div>
              ))}
            </div>
          </p>
          <p className="my-2">
            <strong>Items price: </strong>€{order.itemsPrice}
          </p>
          <p className="my-2">
            <strong>Shipping fee: </strong>€{order.shippingPrice}
          </p>
          <p className="my-2">
            <strong>Taxes: </strong>€{order.taxPrice}
          </p>
          <p className="my-2">
            <strong>Total price: </strong>€{order.totalPrice.toFixed(3)}
          </p>
          <p className="my-2">
            <strong>Payment method: </strong>
            {order.paymentMethod}
          </p>
          <p className="my-2">
            <strong>Payment State: </strong>
            {order.isPaid ? "Paid" : "Pending"}
          </p>
          <p className="my-2">
            <strong>created at:</strong>{" "}
            {moment(order.createdAt).format("DD MMMM YYYY")}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
