import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Store } from "../../Store";
import { Table } from "react-bootstrap";
import moment from "moment";
import "./OrderDash.css";
import OrderDetailsModal from "./OrderDetailsModal";
import OrdersDashRow from "./OrdersDashRow";
import AdminNav from "./AdminNav";

export default function OrdersDash() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  let filteredOrders = [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://vapey-loc.onrender.com/api/orders/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setOrders(response.data);
      } catch (error) {
        console.log("while trying to fetch all orders :", error);
      }
    };
    fetchData();
  }, [userInfo]);

  const handleDeleteOrder = (orderId) => {
    axios
      .delete(`https://vapey-loc.onrender.com/api/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
      .then((response) => {
        // remove the deleted order from the orders array
        const updatedOrders = orders.filter((order) => order._id !== orderId);
        setOrders(updatedOrders);
        console.log(response.data); // handle response data
      })
      .catch((e) => {
        console.log(e.message); // handle error
      });
  };

  const handleShowDetails = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleFilterChange = (event) => {
    const filter = event.target.value;
    setFilterValue(filter);
  };

  // filter the orders array based on the selected filter value
  if (filterValue === "stripe" || filterValue === "paypal") {
    filteredOrders = orders.filter(
      (order) => order.paymentMethod === filterValue
    );
  }
  if (filterValue === "true" || filterValue === "false") {
    if (filterValue === "false") {
      filteredOrders = orders.filter((order) => order.isPaid === false);
    }

    if (filterValue === "true") {
      filteredOrders = orders.filter((order) => order.isPaid === true);
    }

    console.log(filterValue);
    console.log(filteredOrders);
  }
  if (filterValue === "") {
    filteredOrders = orders;
  }

  return (
    <>
      <AdminNav />
      <div className="orders__page">
        <div className="orders_page_content">
          <div className="orders_table">
            <Table responsive className="orders_table text-center">
              <thead>
                <tr>
                  <th colSpan={7}>
                    <div className="d-flex justify-content-end">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={handleFilterChange}
                      >
                        <option selected value="">
                          {filterValue === "" ? "Filter" : "Reset"}
                        </option>
                        <optgroup label="PAYEMENT METHODE">
                          <option value="paypal">Paypal</option>
                          <option value="stripe">Stripe</option>
                        </optgroup>
                        <optgroup label="PAIEMENT STATE">
                          <option value="true">Paid</option>
                          <option value="false">Pending</option>
                        </optgroup>
                      </select>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>Client</th>
                  <th>Order Date</th>
                  <th>Total price</th>
                  <th>Payment State</th>
                  <th colSpan={2}>Order action</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.length === 0 ? (
                  <tr><td className="text-center display-6" colSpan={6}>No orders found .</td></tr>
                ) : (
                  filteredOrders.map((order, index) => (
                    <OrdersDashRow
                      key={index}
                      index={index}
                      order={order}
                      handleDeleteOrder={handleDeleteOrder}
                      handleShowDetails={handleShowDetails}
                      orders={orders}
                      setOrders={setOrders}
                    />
                  ))
                )}
              </tbody>
            </Table>
          </div>
          {selectedOrder && (
            <OrderDetailsModal
              order={selectedOrder}
              show={showModal}
              handleClose={() => {
                setSelectedOrder(null);
                setShowModal(false);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
