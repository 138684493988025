import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Steps from "../components/steps/Steps";
import { Store } from "../Store";
import "./signIn.css";
import Swal from "sweetalert2";

export default function SignInScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectURL = new URLSearchParams(search).get("redirect");
  const redirect = redirectURL ? redirectURL : "/";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        "https://vapey-loc.onrender.com/api/users/signin",
        {
          email,
          password,
        }
      );
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      navigate(redirect || "/");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Hoppla...",
        text: "Ungültige E-Mail oder Passwort!",
      });
    }
  };
  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsIOS(/iPhone|iPad|iPod/.test(userAgent));
  }, []);
  return (
    <div>
      <Helmet>
        <title>Anmelden</title>
      </Helmet>

      <main className="container mt-5">
        <Steps step1></Steps>
        <form
          className={
            isIOS
              ? "form-container-ios text-light"
              : "form-container text-light mt-5"
          }
          onSubmit={submitHandler}
        >
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              E-Mail Adresse
            </label>
            <input
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Passwort
            </label>
            <input
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              id="exampleInputPassword1"
            />
          </div>

          <div className="sign-in-btn-container">
            <button
              type="submit"
              className={
                isIOS ? "sign-in-btn-ios" : "sign-in-btn"
              }
            >
              Anmeldung
            </button>
          </div>
        </form>
        <div className="sign-up-btn-container mt-5">
          <span className="mb-4 text-light">
            Neue Kundin ?{" "}
            <Link to={`/signup?redirect=${redirect}`}>Konto erstellen</Link>
          </span>
          {/* hi */}
        </div>
      </main>
    </div>
  );
}
