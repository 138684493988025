import React, { useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";

export default function OrdersDashRow({
  order,
  index,
  handleDeleteOrder,
  handleShowDetails,
  orders,
  setOrders,
}) {
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const confirmDelete = () => {
    if (confirmingDelete) {
      handleDeleteOrder(order._id);
    } else {
      setConfirmingDelete(true);
      setTimeout(() => {
        setConfirmingDelete(false);
      }, 3000);
    }
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{order.shippingAddresse.fullName}</td>
      <td>{moment(order.createdAt).format("DD MMMM YYYY")}</td>
      <td>€ {order.totalPrice.toFixed(3)}</td>
      <td className="">
        {order.isPaid ? (
          <span>
       
            <i class="bi bi-circle-fill text-success px-1"></i>Paid
          </span>
        ) : (
          <span>
         
            <i class="bi bi-circle-fill text-danger px-1"></i>Pending
          </span>
        )}
      </td>
      <td>
        <Button
          variant="danger"
          className="rounded-pill"
          onClick={confirmDelete}
        >
          {confirmingDelete ? "Confirm" : "Delete"}
        </Button>
      </td>
      <td>
        <Button
          variant="info"
          className="rounded-pill"
          onClick={() => handleShowDetails(order)}
        >
          Details
        </Button>
      </td>
    </tr>
  );
}
