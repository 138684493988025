import axios from "axios";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Store } from "../Store";
import "./Product.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Product(props) {
  const { product } = props;
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;
  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(
      `https://vapey-loc.onrender.com/api/products/${item._id}`
    );

    if (data.countInStock < quantity) {
      Swal.fire({
        icon: 'question',
        title: 'Oops...',
        text: 'Sorry the product is out of stock !',
       
      })
      return;
    }
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...item, quantity },
    });
  };

  return (
    <div className="-card m-3 col" id="card">
      <div className="wrapper h-100">
        {window.innerWidth > 400 ? (
          <LazyLoadImage
            effect="blur"
            src={product.image}
            alt={product.name}
            className="cover-image"
            id="bg"
          />
        ) : (
          <img
            src={product.image}
            alt={product.name}
            className="cover-image"
            id="bg"
          />
        )}
      </div>
      <img src={product.imageNoBg} alt={product.name} className="character" />
      <Link to={`/product/${product.slug}`} className="fixTitle">
        <h2>{product.name}</h2>
      </Link>
      <div className="contentBx">
        <div className="title">
          <div className="price mb-3 d-flex justify-content-center w-100">
            <h3 className="m-0 text-center fw-semibold fs-4 priceProduct">
              €{product.price}
            </h3>
          </div>
          {product.countInStock === 0 ? (
            <button type="button" disabled className="btn-clear bg-danger">
              nicht lieferbar
            </button>
          ) : (
            <button
              onClick={() => addToCartHandler(product)}
              type="button"
              className="btn-clear"
            >
              In den warenkorb
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
