import React, { useEffect, useState } from "react";
import OrdersDash from "./OrdersDash";
import "./AdminDash.css";
import axios from "axios";

export default function AdminDash() {
  useEffect(() => {
    const storedUser = localStorage.getItem("userInfo");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const [User, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get(
          `https://vapey-loc.onrender.com/api/users/${User._id}`
        );
        const user = response.data;
        console.log("returned user from db", user);
        setIsAdmin(user.isAdmin);
      } catch (error) {
        console.error(error);
      }
    }

    if (User) {
      getUser();
    }
  }, [User]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isAdmin ? (
        <>
          <OrdersDash />
        </>
      ) : (
        <div className="div-denied bg-dark d-flex align-items-center justify-content-center">
          <h1 className="text-warning text-center display-1">
            {isLoading ? "Loading..." : "⚠️ access denied !!!"}
          </h1>
        </div>
      )}
    </>
  );
}
