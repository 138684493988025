import "./banner.css";
import React, { useEffect, useReducer, useState } from "react";
import { getError } from "../../utils/utils";
import axios from "axios";
// import logger from "use-reducer-logger";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function Banner() {
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_REQUEST":
        return { ...state, loading: true };
      case "FETCH_SUCCESS":
        return { ...state, products: action.payload, loading: false };
      case "FETCH_FAIL":
        return { ...state, loading: false, error: action.payload };

      default:
        return state;
    }
  };
  //pulled 12/04
  // const [products,setProducts] = useState([]);
  const [dispatch] = useReducer((reducer), {
    products: [],
    loading: true,
    error: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(
          "https://vapey-loc.onrender.com/api/products"
        );
        dispatch({ type: "FETCH_SUCCESS", payload: result.data.products });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
      // setProducts(result.data)
    };
    fetchData();
  }, []);
    // const [products,setProducts] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        dispatch({ type: "FETCH_REQUEST" });
        try {
          const result = await axios.get("https://vapey-loc.onrender.com/api/products");
          dispatch({ type: "FETCH_SUCCESS", payload: result.data.products });
        } catch (error) {
          dispatch({ type: "FETCH_FAIL", payload: getError(error) });
        }
        // setProducts(result.data)
      };
      fetchData();
    }, []);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsIOS(/iPhone|iPad|iPod/.test(userAgent));
  }, []);
  return (
    <div className="b-cont" id="home">
      <div
        className={
          isIOS
            ? "warningIos bg-black text-light"
            : "warning bg-black text-light"
        }
      >
        <p>
          ACHTUNG: Dieses Produkt enthält Nikotin.
          <br />
          Nicotin ist eine suchterzeugende Substanz.
        </p>
        {/* .. */}
      </div>
      <div className="main-banner mb-5">
        <LazyLoadImage
          effect="blur"
          src={
            "https://res.cloudinary.com/dvjvlobqp/image/upload/v1681294460/vapey-assets/bAll_bkwxsp_ntyjhm.webp"
          }
          className="h-100 w-100"
          alt="main banner"
        />
      </div>
    </div>
  );
}
