import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import { StoreProvider } from "./Store";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <StoreProvider>
      <HelmetProvider>
        <PayPalScriptProvider
          options={{
            "client-id":
              "AXPtuhEQ-U-F2vMlCv_VgQdVzJvS3ngKGtETZLYbtRNdrQ6soHrVg8LKhjbsTv82AhQsn62-IjFJJXp7",
          }}
          deferLoading={true}
        >
          <App />
        </PayPalScriptProvider>
      </HelmetProvider>
    </StoreProvider>
  </React.StrictMode>
);
