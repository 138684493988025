import React, { useEffect, useReducer } from "react";
import axios from "axios";
// import logger from "use-reducer-logger";
import { Helmet } from "react-helmet-async";
import Message from "../components/Message";
import Loading from "../components/loading/Loading";
import { getError } from "../utils/utils";
import Categories from "../components/categories/categories";
import Carousel2 from "../components/carousel2/Carousel2";
import Specs from "../components/specs/Specs";
import Banner from "../components/banner/banner";

export default function HomeScreen() {
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_REQUEST":
        return { ...state, loading: true };
      case "FETCH_SUCCESS":
        return { ...state, products: action.payload, loading: false };
      case "FETCH_FAIL":
        return { ...state, loading: false, error: action.payload };

      default:
        return state;
    }
  };
  // const [products,setProducts] = useState([]);
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(
          "https://vapey-loc.onrender.com/api/products"
        );
        dispatch({ type: "FETCH_SUCCESS", payload: result.data.products });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
      // setProducts(result.data)
    };
    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>vapyvape</title>
      </Helmet>

      {loading ? (
        <Loading></Loading>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <>
          <Banner />
          <Categories />

          <Carousel2 />
          <Specs />
          {/* Features */}
        </>
      )}
    </div>
  );
}
