import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ProductsDashRow({
  rowKey,
  index,
  product,
  products,
  setProducts,
}) {
  const [newCountInStock, setNewCountInStock] = useState(product.countInStock);

  useEffect(() => {
    // Update newCountInStock whenever product prop changes .
    setNewCountInStock(product.countInStock);
  }, [product]);

  const handleCountInStockChange = (event) => {
    setNewCountInStock(event.target.value);
  };

  const handleUpdateStock = async () => {
    try {
      let countInStock = newCountInStock;
      if (
        countInStock === "" ||
        countInStock === null ||
        countInStock === undefined
      ) {
        countInStock = 0;
      }
      const response = await fetch(
        `https://vapey-loc.onrender.com/api/products/${product._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ countInStock }),
        }
      );
      const updatedProduct = await response.json();
      setProducts(
        products.map((p) => (p._id === updatedProduct._id ? updatedProduct : p))
      );
      toast.success("✅ Count in stock updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      console.error(error);
      toast.error("❌ an error has occured", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <tr key={rowKey}>
        <td>{index + 1}</td>
        <td>{product.name}</td>
        <td>{product.brand}</td>
        <td>{product.category}</td>
        <td>£ {product.price}</td>
        <td className="stock-col">
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              aria-label="product stock"
              aria-describedby="button-addon2"
              value={newCountInStock}
              onChange={handleCountInStockChange}
              placeholder="0 if empty"
            />
            <button
              className="btn btn-outline-success btn-primary text-light"
              type="button"
              id="button-addon2"
              onClick={handleUpdateStock}
            >
              Update Stock
            </button>
          </div>
        </td>
      </tr>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}
