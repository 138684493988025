import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./completion.css";
import { Store } from "../Store";
import Recommendation from "../components/recommendation/Recommendation";

export default function Completion() {

  const { state } = useContext(Store);
  const { cart } = state;

  const navigate = useNavigate();

  useEffect(() => {
    if (!cart.paymentMethod) {
      navigate("/payment");
    }
  }, [cart, navigate]);

  const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100; //123.2345 => 123.23
  cart.itemsPice = round2(
    cart.cartItems.reduce((a, c) => a + c.quantity * c.price, 0)
  );
  cart.shippingPrice = cart.cartItems.length >= 4 ? 2.99 : 1.95;
  cart.taxPrice = round2(0.19 * cart.itemsPice);
  cart.totalPrice = cart.itemsPice + cart.shippingPrice + cart.taxPrice;

  window.onbeforeunload = function () {
    return "you can not refresh the page";
  };

  useEffect(() => {
    if (!cart.paymentMethod) {
      navigate("/payment");
    }
  }, [cart, navigate]);

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://worldtimeapi.org/api/timezone/Etc/UTC"
      );
      const data = await response.json();
      const date = new Date(data.datetime);
      const formattedDate = date.toLocaleDateString("en-GB");
      setCurrentDate(formattedDate);
    };

    fetchData();
  }, []);

  const dateString = currentDate;
  const [day, month, year] = dateString.split("/").map(Number);
  const date = new Date(year, month - 1, day);
  date.setDate(date.getDate() + 3);
  const formattedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  // added real-time date

  function clearCart() {
    localStorage.removeItem("cartItems");
  }
  return (
    <div className="thanks-cont container-responsive">
      <div className="thanks mb-5">
        <h1>DANKE FÜR IHRE BESTELLUNG {cart.shippingAddresse.fullName}</h1>
        <p className="text-light">Wir hoffen, dich bald wieder zu sehen!</p>
        <p className="text-light">
          Ihre Bestellung wird geliefert am {formattedDate}. Sie können Ihre
          Sendung verfolgen{" "}
          <Link to={"/orderhistory"} className="link">
            hier.
          </Link>
        </p>
      </div>
      <Recommendation />
    </div>
  );
}
