import React, { useRef, useState } from "react";
import validator from "validator";
import "./footer.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function Footer() {
  const [isClicked, setIsClicked] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidMsg, setIsValidMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsValidEmail(validator.isEmail(value) && value.length < 60);
  };

  const handleMsgChange = (event) => {
    const value = event.target.value;
    setMsg(value);
    setIsValidMsg(value.length < 500 && value.length > 49);
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setName(value);
    setIsValidName(/^[a-zA-Z\s'"]+$/.test(value) && value.length < 50);
  };

  const handleClick = () => {
    setIsClicked(true);
  };

  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidName) {
      Swal.fire({
        icon: "error",
        title: "Hoppla...",
        text: "Ungültiger Name!",
      });
      return;
    }
    if (!isValidEmail) {
      Swal.fire({
        icon: "error",
        title: "Hoppla...",
        text: "Ungültige E-Mail-Adresse!",
      });
      return;
    }
    if (isValidEmail && isValidName && isValidMsg) {
      emailjs
        .sendForm(
          "service_wr3afqk",
          "template_by6yf1k",
          e.target,
          "HU_ihDoGwbMYakfSF"
        )
        .then(
          (result) => {
            Swal.fire({
              icon: "success",
              title: "E-Mail",
              text: "E-Mail erfolgreich versendet",
            });
            formRef.current.reset();
          },
          (error) => {
            Swal.fire({
              icon: "error",
              title: "E-Mail",
              text: "E-Mail nicht abgeschickt",
            });
          }
        );
    }
  };
  return (
    <footer id="footerVape">
      <div className="shop-now w-100 text-center text-light py-2 d-flex flex-column justify-content-between">
        <div className="d-flex flex-column justify-content-between h-100">
          <p className="h3 text-center my-3 ">KONTAK</p>
          <form
            name="sentMessage"
            className="container w-50 kontakt"
            // validate
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    onChange={handleNameChange}
                    maxLength="50"
                  />
                  {!isValidName && isClicked ? (
                    <p className="mt-2 text-warning">Ungültiger Name!</p>
                  ) : null}
                  <p className="help-block text-danger"></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={handleEmailChange}
                    maxLength="100"
                  />
                  {!isValidEmail && isClicked ? (
                    <p className="mt-2 text-warning">
                      Ungültige E-Mail-Adresse!
                    </p>
                  ) : null}
                  <p className="help-block text-danger"></p>
                </div>
              </div>
            </div>
            <div className="form-group">
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows="4"
                placeholder="Nachricht"
                onChange={handleMsgChange}
                maxLength="500"
                minLength="50"
              ></textarea>
              {!isValidMsg && isClicked ? (
                <p className="mt-2 text-warning">
                  Die Nachricht muss 50-500 Zeichen lang sein!
                </p>
              ) : null}
              <p className="help-block text-danger"></p>
            </div>
            <div id="success"></div>

            <button
              className="btn foot-btn"
              type="submit"
              onClick={handleClick}
            >
              Schicken
            </button>
          </form>

          {/* <ul className="text-center">
            <li>
              <Link className="animated-arrow" to={"/flavors"}>
                <span className="the-arrow -left">
                  <span className="shaft"></span>
                </span>
                <span className="main1 fs-4 text-fx-2">
                  <span className="text">Jetzt kaufen</span>
                  <span className="the-arrow -right">
                    <span className="shaft"></span>
                  </span>
                </span>
              </Link>
            </li>
          </ul> */}
          <div></div>
        </div>
        <div className="container-fluid px-0 w-100">
          <footer className="text-center text-white">
            <div className="container p-4 pb-0">
              <section className="mb-4">
                <Link
                  className="btn btn-outline-light btn-floating m-1"
                  to={"https://www.facebook.com"}
                  target="_blank"
                  role="button"
                >
                  <i className="bi bi-facebook"></i>
                </Link>
                <Link
                  className="btn btn-outline-light btn-floating m-1"
                  to={"https://www.instagram.com"}
                  target="_blank"
                  role="button"
                >
                  <i className="bi bi-instagram"></i>
                </Link>
              </section>
            </div>
            <div className="text-center p-3">
              © 2023 Urheberrecht:{" "}
              <Link className="text-white ms-1 text-fx-2" to={"/"}>
                VapyVape
              </Link>
            </div>
          </footer>
        </div>
      </div>
    </footer>
  );
}
