import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import HomeScreen from "./screens/homeScreen";
import ProductScreen from "./screens/productScreen";
import CartScreen from "./screens/CartScreen";
import SignInScreen from "./screens/SignInScreen";
import ShippingAdresseScreen from "./screens/ShippingAdresseScreen";
import SignUpScreen from "./screens/SignUpScreen";
import PaymentMethodScreen from "./screens/PaymentMethodScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import Completion from "./screens/Completion";
import OrderHistoryScreen from "./screens/OrderHistoryScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import Navbar from "./components/navbar/navbar";
import "./App.css";
import Delivrey from "./screens/delivrey/Delivrey";
import Flavors from "./components/flavors/Flavors";
import Footer from "./footer/Footer";
import ScrollToTop from "./components/ScrollTop";
import AdminDash from "./components/AdminDashboard/AdminDash";
import OrdersDash from "./components/AdminDashboard/OrdersDash";
import AdminProtectedRoute from "./components/AdminDashboard/AdminProtectedRoute";
import { useEffect, useState } from "react";
import ProductsDash from "./components/AdminDashboard/ProductsDash";
import axios from "axios";

export default function App() {
  // checkpoint
  // document.addEventListener('contextmenu', function(e) {
  //   e.preventDefault();

  // });
  // document.onkeydown = function(e) {
  //   if(e.keyCode == 123) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
  //      return false;
  //   }
  //   if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
  //      return false;
  //   }
  // }
  // const currentPath = window.location.href;
  // console.log(currentPath);
  // const hideNavbarRoutes = ["/admin/dashboard", "/admin/dashboard/orders"];
  // const hideNav = hideNavbarRoutes.some((str) => currentPath.includes(str));
  // console.log(hideNav);
  const [User, setUser] = useState(null);
  useEffect(() => {
    const storedUser = localStorage.getItem("userInfo");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get(
          `https://vapey-loc.onrender.com/api/users/${User._id}`
        );
        const user = response.data;
        console.log("returned user from db", user);
        setIsAdmin(user.isAdmin);
      } catch (error) {
        console.error(error);
      }
    }

    if (User) {
      getUser();
    }
  }, [User]);
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar />
        <main>
          <div className="filler"></div>

          <Routes>
            <Route
              exact
              path="/product/:slug"
              element={<ProductScreen />}
            ></Route>
            <Route exact path="/" element={<HomeScreen />}></Route>
            <Route exact path="/cart" element={<CartScreen />}></Route>
            <Route exact path="/signin" element={<SignInScreen />}></Route>
            <Route exact path="/signup" element={<SignUpScreen />}></Route>
            <Route
              exact
              path="/shipping"
              element={<ShippingAdresseScreen />}
            ></Route>
            <Route
              exact
              path="/payment"
              element={<PaymentMethodScreen />}
            ></Route>
            <Route
              exact
              path="/placeOrder"
              element={<PlaceOrderScreen />}
            ></Route>
            <Route path="/order/:id" element={<OrderScreen />}></Route>
            <Route path="/completion" element={<Completion />}></Route>
            <Route
              path="/orderhistory"
              element={
                <ProtectedRoute>
                  <OrderHistoryScreen />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/admin/dashboard/orders"
              element={
                <AdminProtectedRoute>
                  <AdminDash />
                </AdminProtectedRoute>
              }
            ></Route>
            <Route
              path="/admin/dashboard/products"
              element={
                <AdminProtectedRoute>
                  <ProductsDash />
                </AdminProtectedRoute>
              }
            ></Route>
            <Route
              path="/admin/dashboard"
              element={
                <AdminProtectedRoute>
                  <OrdersDash />
                </AdminProtectedRoute>
              }
            ></Route>
            <Route exact path="/delivrey" element={<Delivrey />}></Route>
            <Route exact path="/flavors" element={<Flavors />}></Route>
          </Routes>
        </main>
      </div>
      {isAdmin ? null : <Footer />}
    </Router>
  );
}
