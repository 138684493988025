import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../Store";
import "./navbar.css";
import axios from "axios";

export default function Navbar() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;
  const signoutHandler = () => {
    ctxDispatch({ type: "USER_SINGOUT" });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("shippingAddress");
    localStorage.removeItem("paymentMethod");
    window.location.href = "/signin";
  };

  function buttonclick() {
    var pagebutton = document.getElementById("selfclick3");
    if (window.innerWidth <= 992) {
      pagebutton.click();
    }
  }

  function linkClickHome() {
    buttonclick();
    handleClickScrollHome();
  }

  function linkClickProds() {
    buttonclick();
    handleClickScrollProds();
  }

  const handleClickScrollHome = () => {
    const element = document.getElementById("home");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickScrollProds = () => {
    const element = document.getElementById("prods");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [User, setUser] = useState(null);

  const onLoadAdmin = () => {
    const storedUser = localStorage.getItem("userInfo");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  };

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get(
          `https://vapey-loc.onrender.com/api/users/${User._id}`
        );
        const user = response.data;
        console.log("returned user from db", user);
        setIsAdmin(user.isAdmin);
      } catch (error) {
        console.error(error);
      }
    }

    if (User) {
      getUser();
    }
  }, [User]);

  return (
    <nav
      className="navbar bg-dark navbar-expand-lg top-nav"
      onLoad={onLoadAdmin}
    >
      <div className="container-fluid">
        <Link className="navbar-brand text-light" to="/">
          <img
            src="https://res.cloudinary.com/dvjvlobqp/image/upload/v1681294622/vapey-assets/icons/vapyvape_husdjj_c7g3vv.webp"
            width={"70px"}
            className="mx-3"
            alt="logo"
          />
        </Link>
        <button
          className="navbar-toggler border-0 collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="selfclick3"
        >
          <span className="navbar-toggler">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2rem"
              height="2rem"
              fill="#fff"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </span>
        </button>
        <div
          className="collapse navbar-collapse ms-lg-5 justify-content-between bg-trans-black"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0 justify-content-center -nav-links">
            <li className="nav-item">
              <Link
                className="nav-link me-md-5"
                to={"/"}
                onClick={linkClickHome}
              >
                UNSERE MARKE
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link me-md-5"
                to={"/flavors"}
                onClick={linkClickProds}
              >
                PRODUKTE
              </Link>
            </li>
            {!isAdmin ? (
              <li className="nav-item">
                <a
                  className="nav-link text-uppercase"
                  href="#footerVape"
                  onClick={buttonclick}
                >
                  KONTAKT
                </a>
              </li>
            ) : null}
          </ul>
          <div className="d-flex flex-lg-row flex-column flex-nowrap align-items-center justify-content-lg-between justify-content-center -nav-menu">
            {/* <div className="search-div">
              <div className="search-container">
                <div>
                  <input
                    className="search expandright"
                    id="searchright"
                    type="search"
                    name="q"
                    placeholder="Search"
                  />
                  <label className="button searchbutton" htmlFor="searchright">
                    <span className="mglass">⚲</span>
                  </label>
                </div>
              </div>
            </div> */}

            {/* <Link to="/profile" role="button" className="text-decoration-none"> */}
            <div className="usr-links mb-3">
              {userInfo ? (
                <span className="dropdown">
                  <button
                    className="btn btn-dark dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="text-light mx-2">{userInfo.name}</span>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-dark usr-menu">
                    <li className="d-inline ms-2 me-3">
                      {isAdmin ? (
                        <Link to={"/admin/dashboard"}>
                          <img
                            alt="history"
                            className="history"
                            src="https://res.cloudinary.com/dvjvlobqp/image/upload/v1681465280/vapey-assets/icons/icon-admin_mvic2z.svg"
                            width={"20px"}
                            onClick={buttonclick}
                          />
                        </Link>
                      ) : (
                        <Link role="button" to="/orderHistory">
                          <img
                            alt="history"
                            className="history"
                            src="https://cdn-icons-png.flaticon.com/512/32/32223.png"
                            width={"20px"}
                            onClick={buttonclick}
                          />
                        </Link>
                      )}
                    </li>
                    <li className="d-inline">
                      <Link
                        role="button"
                        to="#signout"
                        onClick={signoutHandler}
                      >
                        <button className="btn btn-sm mx-2 btn-secondary">
                          Abmelden
                        </button>
                      </Link>
                    </li>
                  </ul>
                </span>
              ) : (
                <Link
                  to="/signin"
                  className="text-decoration-none text-white mx-3"
                  onClick={buttonclick}
                >
                  Anmelden
                </Link>
              )}
              {/*             
              </Link> */}
              <Link
                to="/cart"
                role="button"
                className="text-decoration-none cart d-flex align-items-center justify-content-center ps-2"
                onClick={buttonclick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.2rem"
                  height="1.2rem"
                  fill="white"
                  className="bi bi-bag-fill "
                  viewBox="0 0 16 16"
                >
                  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5z" />
                </svg>

                <span className="text-light me-3 ms-1 mt-1">
                  {" "}
                  {cart.cartItems.length > 0 && (
                    <span>
                      {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
                    </span>
                  )}
                </span>
              </Link>
              {/* <select
                className="form-select bg-dark text-light border-none text-center w-auto"
                aria-label="Translate-btn"
                defaultValue={"de"}
              >
                <option value="de">De</option>
                <option value="en">En</option>
              </select> */}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
