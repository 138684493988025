import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Store } from "../../Store";
import axios from "axios";

export default function AdminProtectedRoute({ children }) {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    async function getUser() {
      try {
        const response = await axios.get(
          `https://vapey-loc.onrender.com/api/users/${userInfo._id}`
        );
        const user = response.data;
        setIsAuth(user.isAdmin);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (userInfo) {
      getUser();
    }
  }, [userInfo]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuth ? children : <Navigate to="/" />;
}
