import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Message from "../components/Message";
import Loading from "../components/loading/Loading";
import { getError } from "../utils/utils";
import { Store } from "../Store";
import Magnifier from "react-magnifier";
import "./product.css";
import Swal from "sweetalert2";
import { auto } from "@popperjs/core";
import Recommendation from "../components/recommendation/Recommendation";

export default function ProductScreen() {
  // const reducer = (state, action) => {
  //   switch (action.type) {
  //     case "FETCH_REQUEST":
  //       return { ...state, loading: true };
  //     case "FETCH_SUCCESS":
  //       return { ...state, products: action.payload, loading: false };
  //     case "FETCH_FAIL":
  //       return { ...state, loading: false, error: action.payload };

  //     default:
  //       return state;
  //   }
  // };
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_REQUEST":
        return { ...state, loading: true };
      case "FETCH_SUCCESS":
        return { ...state, product: action.payload, loading: false };
      case "FETCH_FAIL":
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  var isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // const navigate = useNavigate();
  // const params = useParams();
  // const { slug } = params;
  // const [{ loading, error, product }, dispatch] = useReducer(
  //   reducer,
  //   {
  //     product: [],
  //     loading: true,
  //     error: "",
  //   }
  // );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     dispatch({ type: "FETCH_REQUEST" });
  //     try {
  //       const result = await axios.get(
  //         `https://vapey-loc.onrender.com/api/products/slug/${slug}`
  //       );

  //       dispatch({ type: "FETCH_SUCCESS", payload: result.data });
  //     } catch (error) {
  //       dispatch({ type: "FETCH_FAIL", payload: getError(error) });
  //     }
  //   };
  //   fetchData();
  // }, [slug]);

  // const addToCartHandler = async () => {
  //   const existItem = cart.cartItems.find((x) => x._id === product._id);
  //   const quantity = existItem ? existItem.quantity + 1 : 1;
  //   const { data } = await axios.get(
  //     `https://vapey-loc.onrender.com/api/products/${product._id}`
  //   );
  //   if (data.countInStock < quantity) {
  //     Swal.fire({
  //       icon: "question",
  //       title: "Oops...",
  //       text: "Sorry the product is out of stock !",
  //     });
  //     return;
  //   }
  //   ctxDispatch({
  //     type: "CART_ADD_ITEM",
  //     payload: { ...product, quantity },
  //   });
  //   navigate("/cart");
  // };

  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;
  const [{ loading, error, product }, dispatch] = useReducer(reducer, {
    product: [],
    loading: true,
    error: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(
          `https://vapey-loc.onrender.com/api/products/slug/${slug}`
        );

        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
    };
    fetchData();
  }, [slug]);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart } = state;
  const addToCartHandler = async () => {
    const existItem = cart.cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(
      `https://vapey-loc.onrender.com/api/products/${product._id}`
    );
    if (data.countInStock < quantity) {
      Swal.fire({
        icon: "question",
        title: "Oops...",
        text: "Sorry the product is out of stock !",
      });
      return;
    }
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...product, quantity },
    });
    navigate("/cart");
  };

  useEffect(() => {
    setCurrentImage(product.imageNoBg);
    function handleResize() {
      const width = window.innerWidth;
      if (width > 768) {
        setHeightValue(368);
      } else if (width < 768 && width > 425) {
        setHeightValue(320);
      } else if (width < 425) {
        setHeightValue(250);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [currentImage, setCurrentImage] = useState(product.imageNoBg);
  const [heightValue, setHeightValue] = useState(0);

  function buttonclick9() {
    var pagebutton = document.getElementById("selfclick9");
    pagebutton.click();
  }

  return loading ? (
    <Loading></Loading>
  ) : error ? (
    <Message>{error}</Message>
  ) : (
    <div className="mt-5">
      <Helmet>
        <title>{product.name}</title>
      </Helmet>
      <main className="py-5 min-vh-100 d-flex flex-column align-items-center">
        <div className="container d-flex flex-lg-row justify-content-lg-center align-items-center flex-column mb-lg-5 pb-3 pb-lg-0">
          {isIos ? (
            <div className="magn-cont-ios mb-5 mb-md-0">
              <div className="magn-ios">
                <Magnifier src={currentImage} />
              </div>
            </div>
          ) : window.innerWidth > 764 && window.innerWidth < 1030 ? (
            <div className="magn-cont-tab mb-5 mb-lg-0">
              <div className="magn-tab">
                <Magnifier src={currentImage} />
              </div>
            </div>
          ) : window.innerWidth > 1030 && window.innerWidth < 1370 ? (
            <div className="magn-cont-tab mb-5 mb-lg-0">
              <div className="magn-tab">
                <Magnifier src={currentImage} height={heightValue} />
              </div>
            </div>
          ) : (
            <div className="magn-cont mb-5 mb-md-0">
              <Magnifier src={currentImage} width={auto} height={heightValue} />
            </div>
          )}

          <div className="me-lg-5 me-md-3"></div>
          <div className="d-flex flex-column justify-content-evenly">
            <h2 className="text-light">{product && product.name}</h2>
            <h6 className="text-secondary">
              SMOKE -{" "}
              <span className="text-light">{product && product.category}</span>
            </h6>
            <span className="text-light display-6 fst-italic">
              {" "}
              € {product.price}
            </span>
            {product.countInStock > 0 ? (
              <div className="text-success display-2">lieferbar</div>
            ) : (
              <div className="text-danger display-2">nicht lieferbar</div>
            )}
            {product.countInStock > 0 ? (
              <button
                className="btn btn-success text-center"
                onClick={addToCartHandler}
              >
                In den warenkorb
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="product-sides">
          <div className="side-cont">
            <img
              src={product.imageNoBg}
              alt="side"
              className="side-img"
              onClick={() => setCurrentImage(product.imageNoBg)}
              id="selfclick9"
              onLoad={buttonclick9}
            />
          </div>
          <div className="side-cont">
            <img
              src={product.imageBackSide}
              alt="side"
              className="side-img"
              onClick={() => setCurrentImage(product.imageBackSide)}
            />
          </div>
        </div>
        <div className=" w-50 text-light">
          <h2 className="mb-3 col-orng fw-semibold">
            ALLGEMEINE INFORMATIONEN
          </h2>
          <p className="mb-5 text-light">
            <span className="col-orng text-bold h3 d-block">Beschreibung </span>
            {product.description && product.description}
          </p>
          <p className="mb-3 col-orng h3">Spezifikationen</p>

          <ul className="mb-5 text-light">
            <li className="mb-2">Gewicht: 29g</li>
            <li className="mb-2">Batteriekapazität : 500mAh</li>
            <li className="mb-2"> Standby-Strom : &lt; 5uA</li>
            <li className="mb-2"> Eingangsspannung : 3.0V-4.2V</li>
            <li className="mb-2"> Ausgangsspannung : 3.6V</li>
            <li className="mb-2">Ausgangsleistung : Max 12.3W </li>
            <li className="mb-2"> Widerstand : 12Ω±0.15Ω</li>
            <li className="mb-2"> E-Liquid-Kapazität : 2ml</li>
            <li className="mb-2"> Stärke : 20mg/ml</li>
          </ul>
          <p className="mb-3 col-orng h3">Geschmacksrichtung</p>
          <p className="mb-3">{product.category && product.category}</p>
        </div>
      </main>
      <Recommendation excludedId={product.slug} />
    </div>
  );
}

{
  /*
import React, { useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import Message from "../components/Message";
import Loading from "../components/loading/Loading";
import { getError } from "../utils/utils";
import { Store } from "../Store";
import Magnifier from "react-magnifier";
import "./product.css";
import Swal from "sweetalert2";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, product: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function ProductScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;
  const [{ loading, error, product }, dispatch] = useReducer(reducer, {
    product: [],
    loading: true,
    error: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(
          `https://vapyvape.onrender.com/api/products/slug/${slug}`
        );

        dispatch({ type: "FETCH_SUCCESS", payload: result.data });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
    };
    fetchData();
  }, [slug]);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart } = state;
  const addToCartHandler = async () => {
    const existItem = cart.cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(
      `https://vapyvape.onrender.com/api/products/${product._id}`
    );
    if (data.countInStock < quantity) {
      Swal.fire({
        icon: "question",
        title: "Oops...",
        text: "Sorry the product is out of stock !",
      });
      return;
    }
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...product, quantity },
    });
    navigate("/cart");
  };
  return loading ? (
    <Loading></Loading>
  ) : error ? (
    <Message>{error}</Message>
  ) : (
    <div className="mt-5">
      <Helmet>
        <title>{product.name}</title>
      </Helmet>
      <main className="py-5 min-vh-100 d-flex flex-column align-items-center">
        <div className="container d-flex justify-content-center mb-5">
          <Magnifier src={product.image} width={200} />;
          <div className="me-lg-5 me-3">
            {/* <img src="imgs/clear.png" alt />
          </div>
          <div className="d-flex flex-column justify-content-evenly">
            <h2 className="text-light">{product && product.name}</h2>
            <h6 className="text-secondary">
              SMOKE -{" "}
              <span className="text-light">{product && product.category}</span>
            </h6>
            <span className="text-light display-6 fst-italic">
              {" "}
              € {product.price}
            </span>
            {/*       
          <small className="text-secondary">Quantity :</small>
          <input
            type="number"
            className="quant bg-dark text-light rounded px-2 py-1"
            min={1}
            max={50}
            id
          />
         
            {product.countInStock > 0 ? (
              <div className="text-success display-2">lieferbar</div>
            ) : (
              <div className="text-danger display-2">nicht lieferbar</div>
            )}
            {product.countInStock > 0 ? (
              <button
                className="btn btn-success text-center"
                onClick={addToCartHandler}
              >
                In den warenkorb
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className=" w-50 text-light">
          <h2 className="mb-3 text-light">ALLGEMEINE INFORMATIONEN</h2>
          <p className="mb-5 text-light">
            <h3 className="text-info text-bold">Beschreibung </h3>
            {product.description && product.description}
          </p>
          <h3 className="mb-3 text-info">Spezifikationen</h3>

          <ul className="mb-5 text-light">
            <li className="mb-2">Gewicht: 29g</li>
            <li className="mb-2">Batteriekapazität : 500mAh</li>
            <li className="mb-2"> Standby-Strom : &lt; 5uA</li>
            <li className="mb-2"> Eingangsspannung : 3.0V-4.2V</li>
            <li className="mb-2"> Ausgangsspannung : 3.6V</li>
            <li className="mb-2">Ausgangsleistung : Max 12.3W </li>
            <li className="mb-2"> Widerstand : 12Ω±0.15Ω</li>
            <li className="mb-2"> E-Liquid-Kapazität : 2ml</li>
            <li className="mb-2"> Stärke : 20mg/ml</li>
          </ul>
          <h3 className="mb-3 text-info">Geschmacksrichtung</h3>
          <p className="mb-3">{product.category && product.category}</p>
          {/* <h4 className="mb-3">Flavor</h4>
        <p className="mb-3">Clear : Menthol</p>
        </div>
      </main>
      {/*
    </div>
  );
}
*/
}
