import React from "react";
import { Link } from "react-router-dom";
import "./AdminNav.css";

export default function AdminNav() {
  return (
    <div className="admin-nav d-flex align-items-center">
      <ul className="d-flex flex-column flex-md-row align-items-center justify-content-center py-3 w-100 m-0">
        <li className="me-md-5">
          <Link to={"/admin/dashboard/orders"}>Orders</Link>
        </li>
        <li>
          <Link to={"/admin/dashboard/products"}>Products</Link>
        </li>
      </ul>
    </div>
  );
}
