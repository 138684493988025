import React, { useEffect, useReducer, useState } from "react";
import { getError } from "../../utils/utils";
import axios from "axios";
// import logger from "use-reducer-logger";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap"

export default function Carousel1() {
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_REQUEST":
        return { ...state, loading: true };
      case "FETCH_SUCCESS":
        return { ...state, products: action.payload, loading: false };
      case "FETCH_FAIL":
        return { ...state, loading: false, error: action.payload };

      default:
        return state;
    }
  };
  // const [products,setProducts] = useState([]);
  const [{ products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const result = await axios.get(
          "https://vapey-loc.onrender.com/api/products"
        );
        dispatch({ type: "FETCH_SUCCESS", payload: result.data.products });
      } catch (error) {
        dispatch({ type: "FETCH_FAIL", payload: getError(error) });
      }
      // setProducts(result.data)
    };
    fetchData();
  }, []);
  function buttonclick() {
    var pagebutton = document.getElementById("selfclick");
    pagebutton.click();
  }
  return (
    <div
      id="carouselExampleAutoplaying"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="1000">
          <img
            src={
              "https://res.cloudinary.com/dvjvlobqp/image/upload/v1679399636/vapey-assets/flavors/withBg/blueberry-blasting_dc9nd7.jpg"
            }
            className="rounded-5 w-100"
            alt="first-carousel-item"
            onLoad={buttonclick}
          />
        </div>
        {products.map((product) => (
          <div
            className="carousel-item"
            data-bs-interval="1000"
            key={product.slug}
          >
            <img
              src={product.image}
              className="rounded-5 w-100"
              alt="carousel-item"
            />
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev invisible"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next invisible"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next"
        id="selfclick"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
