import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useReducer, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Loading from "./loading/Loading";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchProducts() {
      const response = await fetch("https://vapey-loc.onrender.com/api/products");
      const data = await response.json();
      setProducts(data.products);
    }
    fetchProducts();
  }, []);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    // PUT IS PAID
    if (
      props.orderId._id !== null &&
      props.orderId._id.length > 0 &&
      props.orderId._id !== undefined
    ) {
      try {
        const { data } = axios.put(
          `https://vapey-loc.onrender.com/api/orders/${props.orderId._id}/payStripe`
        );
        // Update the stock of the purchased products
        for (const item of props.orderId.orderItems) {
          const product = products.find((p) => p._id === item.product);
          const countInStock = product.countInStock - item.quantity;
          await axios.put(
            `https://vapey-loc.onrender.com/api/products/${item.product}`,
            { countInStock },
            { headers: { authorization: `Bearer ${userInfo.token}` } }
          );
        }
        localStorage.removeItem("cartItems");
      } catch (error) {
        console.log(error);
      }
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {isProcessing ? (
        <Loading />
      ) : (
        <button
          className="btn btn-success my-3 w-100"
          disabled={isProcessing || !stripe || !elements}
          id="submit"
        >
          <span id="button-success">Pay now</span>
        </button>
      )}

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
//...